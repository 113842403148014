import React, { useState } from 'react';
import './reSignComponent.scss'

const ReSignComponent = () => {
    const [authorName, setAuthorName] = useState('');
    const [penName, setPenName] = useState('');
    const [contractNumber, setContractNumber] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Xử lý logic gửi dữ liệu tái ký hợp đồng ở đây
        console.log('Author Name:', authorName);
        console.log('Pen Name:', penName);
        console.log('Contract Number:', contractNumber);
    };

    return (
        <div className="resign-container">
            <h2>Tái Ký Hợp Đồng</h2>
            <form onSubmit={handleSubmit} className="resign-form">
                <div className="form-group">
                    <label htmlFor="authorName">Tên Tác Giả:</label>
                    <input
                        type="text"
                        id="authorName"
                        value={authorName}
                        onChange={(e) => setAuthorName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="penName">Bút Danh:</label>
                    <input
                        type="text"
                        id="penName"
                        value={penName}
                        onChange={(e) => setPenName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="contractNumber">Số Hợp Đồng:</label>
                    <input
                        type="text"
                        id="contractNumber"
                        value={contractNumber}
                        onChange={(e) => setContractNumber(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="submit-button">Tái Ký</button>
            </form>
        </div>
    );
};

export default ReSignComponent;
