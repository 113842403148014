import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';

// Import your pages
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import MainContentComponent from './components/MainContent/MainContentComponent';
import ReSignComponent from './components/ReSign/ReSignComponent';

function App() {

 

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route 
            path="/dashboard" 
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } 
          >
                <Route path="content" element={<MainContentComponent/>} />
                <Route path="re-sign" element={<ReSignComponent/>} />


             </Route>


        </Routes>
      </Router>
    </div>
  );
}
export default App;
