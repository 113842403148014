import React, { useState, useEffect } from 'react';
import './mainContentComponent.scss';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';


const MainContentComponent = () => {
  const [totalCount, setTotalCount] = useState(null);
  const [terminatedCount, setTerminatedCount] = useState(null);
  const [southOwnerCount, setSouthOwnerCount] = useState(null);
  const [northOwnerCount, setNorthOwnerCount] = useState(null);

  const [searchByCode, setSearchByCode] = useState('');
  const [searchByAuthorName, setSearchByAuthorName] = useState('');
  const [searchByPenName, setSearchByPenName] = useState('');
  const [searchByContract, setSearchByContract] = useState('');

  const [searchResults, setSearchResults] = useState([]);

  const [filterByStatus, setFilterByStatus] = useState('');
  const [filterByDateFrom, setFilterByDateFrom] = useState('');
  const [filterByDateTo, setFilterByDateTo] = useState('');


  const [detailData, setDetailData] = useState('');
  const [showDetailPopup, setShowDetailPopup] = useState(false);
  const [showAddNewPopup, setShowAddNewPopup] = useState(false);
  const [isRenewal, setIsRenewal] = useState(false);

  const [isExporting, setIsExporting] = useState(false);
  const [exportError, setExportError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);


  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState('')
  const apiUrlLocal = process.env.REACT_APP_API_URL_LOCAL;
  const apiUrlProduction = process.env.REACT_APP_API_URL_PRODUCTION;


  const [newMemberData, setNewMemberData] = useState({
    tinh_trang: "Ký mới",
    code: "",
    ten_tac_gia: "",
    tg_nhac_loi: "",
    but_danh: "",
    ngay_sinh: "",
    ngay_mat: "",
    nguoi_dai_dien: "",
    so_hop_dong: "",
    ngay_ky_hop_dong: "",
    ngay_tai_ky: "",
    ngay_het_han_hop_dong: "",
    thoi_gian_nhac_gia_han: "",
    loai_hop_dong: "Tự động GH",
    csh: "Nam",
    dien_thoai: "",
    email_address: "",
    cmnd_cccd: "",
    thong_tin_ngan_hang: "",
    dia_chi_lien_lac: "",
    dia_chi_thuong_tru: "",
    ngay_thanh_ly: "",
    note: "",
    created_by: "",
  });



  const [memberDataIdToModify, setMemberDataIdToModify] = useState(null);
  const [showConfirmModify, setShowConfirmModify] = useState(false);
  const [modifyMemberData, setModifyMemberData] = useState({
    tinh_trang: "",
    code: "",
    ten_tac_gia: "",
    tg_nhac_loi: "",
    but_danh: "",
    ngay_sinh: "",
    ngay_mat: "",
    nguoi_dai_dien: "",
    so_hop_dong: "",
    ngay_ky_hop_dong: "",
    ngay_tai_ky: "",
    ngay_het_han_hop_dong: "",
    thoi_gian_nhac_gia_han: "",
    loai_hop_dong: "",
    csh: "",
    dien_thoai: "",
    email_address: "",
    cmnd_cccd: "",
    thong_tin_ngan_hang: "",
    dia_chi_lien_lac: "",
    dia_chi_thuong_tru: "",
    ngay_thanh_ly: "",
    note: "",
  });

  const [contractIdToViewHistory, setContractIdToViewHistory] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);



  // State to control the visibility of the confirmation modal or popup

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMemberData(prevData => ({ ...prevData, [name]: value }));
    // console.log('data', newMemberData)
  };

  const handleModifyInputChange = (e) => {
    const { name, value } = e.target;
    setModifyMemberData(prevData => ({ ...prevData, [name]: value }));
    // console.log('data', newSongData)
  }

  const navigate = useNavigate();


  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);


    // Kiểm tra nếu ít nhất một trong các trường được điền
    if (!searchByCode && !searchByAuthorName && !searchByPenName && !searchByContract) {
      setLoading(false);
      alert('Vui lòng điền thông tin cần tìm kiếm.');
      return;

    }

    const token = sessionStorage.getItem('_umsid');

    if (!token) {
      alert('Token không tồn tại. Vui lòng đăng nhập lại.');
      return;
    }

    try {
      const response = await fetch(`${apiUrlProduction}/admin/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          code: searchByCode,
          ten_tac_gia: searchByAuthorName,
          but_danh: searchByPenName,
          contract: searchByContract
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setSearchResults(data);
        setDetailData(data);

      } else if (response.status === 403) {
        alert('Bạn không có quyền thực hiện chức năng này. Vui lòng kiểm tra lại quyền của bạn.');
      } else if (response.status === 401) {
        alert('Phiên làm việc của bạn đã hết hạn. Vui lòng đăng nhập lại để tiếp tục.');
        navigate('/');
      } else {
        alert('Có lỗi xảy ra. Vui lòng thử lại sau.');
        console.error('Lỗi:', await response.text());
      }
    } catch (error) {
      console.error('Lỗi:', error.message);
      alert('Có lỗi xảy ra khi kết nối đến server. Vui lòng thử lại sau.');
    } finally {
      setLoading(false);
    }

  };


  const handleGetDetail = (event, id) => {
    event.preventDefault();

    // Tìm item trong mảng `searchResults` dựa trên `id`
    const selectedItem = searchResults.find(item => item._id === id);

    if (selectedItem) {
      // console.log("Chi tiết:", selectedItem); 

      setDetailData(selectedItem);
      setShowDetailPopup(true);
    } else {
      console.error('Không tìm thấy item với id:', id);
    }
  };


  const handleFilter = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Kiểm tra nếu ít nhất một trong các trường được điền
    if (!filterByStatus && !filterByDateFrom && !filterByDateTo) {
      setLoading(false);
      alert('Vui lòng điền thông tin lọc.');
      return;
    }

    const token = sessionStorage.getItem('_umsid');

    if (!token) {
      alert('Token không tồn tại. Vui lòng đăng nhập lại.');
      return;
    }

    try {
      const response = await fetch(`${apiUrlProduction}/admin/filter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          status: filterByStatus,
          dateFrom: filterByDateFrom,
          dateTo: filterByDateTo
        }),
      });

      // console.log("status", filterByStatus)
      // console.log("dateFrom", filterByDateFrom)

      if (response.ok) {
        const data = await response.json();
        setSearchResults(data);
        console.log('Kết quả lọc:', data);
      } else if (response.status === 403) {
        alert('Bạn không có quyền thực hiện chức năng này. Vui lòng kiểm tra lại quyền của bạn.');
      } else if (response.status === 401) {
        alert('Phiên làm việc của bạn đã hết hạn. Vui lòng đăng nhập lại để tiếp tục.');
        navigate('/');
      } else {
        alert('Có lỗi xảy ra. Vui lòng thử lại sau.');
        console.error('Lỗi:', await response.text());
      }
    } catch (error) {
      console.error('Lỗi:', error.message);
      alert('Có lỗi xảy ra khi kết nối đến server. Vui lòng thử lại sau.');
    } finally {
      setLoading(false);
    }
  };






  const handleCloseDetailPopup = () => {
    setShowDetailPopup(false)
  }

  const handleCloseAddNewPopup = () => {
    setShowAddNewPopup(false)
  }

  const handleOpenAddNewPopup = () => {
    setShowAddNewPopup(true)
  }

  const handleCloseModifyPopup = () => {
    setShowConfirmModify(false)
  }


  const handleCloseHistoryPopup = () => {
    setShowHistoryModal(false)
  }

  const handleAddNew = async (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem('_umsid');
    setIsSubmitting(true); // Bắt đầu quá trình gửi dữ liệu
    setSubmitError(false); // Reset trạng thái lỗi trước khi gửi

    if (!token) {
      alert('Token không tồn tại. Vui lòng đăng nhập lại.');
      setIsSubmitting(false); // Kết thúc quá trình gửi dữ liệu khi gặp lỗi
      return;
    }

    let created_by = '';
    try {
      const decodedToken = jwtDecode(token);
      created_by = decodedToken.username;
    } catch (error) {
      console.error('Lỗi khi giải mã token:', error.message);
      alert('Có lỗi xảy ra khi giải mã token. Vui lòng thử lại sau.');
      setIsSubmitting(false); // Kết thúc quá trình gửi dữ liệu khi gặp lỗi
      return;
    }

    const requestData = {
      tinh_trang: newMemberData.tinh_trang,
      code: newMemberData.code,
      ten_tac_gia: newMemberData.ten_tac_gia,
      tg_nhac_loi: newMemberData.tg_nhac_loi,
      but_danh: newMemberData.but_danh,
      ngay_sinh: newMemberData.ngay_sinh,
      ngay_mat: newMemberData.ngay_mat,
      nguoi_dai_dien: newMemberData.nguoi_dai_dien,
      so_hop_dong: newMemberData.so_hop_dong,
      ngay_ky_hop_dong: newMemberData.ngay_ky_hop_dong,
      ngay_tai_ky: newMemberData.ngay_tai_ky,
      ngay_het_han_hop_dong: newMemberData.ngay_het_han_hop_dong,
      thoi_gian_nhac_gia_han: newMemberData.thoi_gian_nhac_gia_han,
      loai_hop_dong: newMemberData.loai_hop_dong,
      csh: newMemberData.csh,
      dien_thoai: newMemberData.dien_thoai,
      email_address: newMemberData.email_address,
      cmnd_cccd: newMemberData.cmnd_cccd,
      thong_tin_ngan_hang: newMemberData.thong_tin_ngan_hang,
      dia_chi_lien_lac: newMemberData.dia_chi_lien_lac,
      dia_chi_thuong_tru: newMemberData.dia_chi_thuong_tru,
      ngay_thanh_ly: newMemberData.ngay_thanh_ly,
      note: newMemberData.note,
      created_by
    };

    try {
      const response = await fetch(`${apiUrlProduction}/admin/add-new-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        alert('Thêm mới thành công!');
        const data = await response.json();
      } else if (response.status === 403) {
        alert('Bạn không có quyền thực hiện chức năng này. Vui lòng kiểm tra lại quyền của bạn.');
        setSubmitError(true);
      } else if (response.status === 401) {
        alert('Phiên làm việc của bạn đã hết hạn. Vui lòng đăng nhập lại để tiếp tục.');
        setSubmitError(true);
      } else {
        alert('Có lỗi xảy ra. Vui lòng thử lại sau.');
        console.error('Lỗi:', await response.text());
        setSubmitError(true); // Gặp lỗi khi gửi
      }
    } catch (error) {
      console.error('Lỗi:', error.message);
      alert('Có lỗi xảy ra khi kết nối đến server. Vui lòng thử lại sau.');
      setSubmitError(true); // Gặp lỗi khi gửi
    } finally {
      setShowAddNewPopup(false);
      setIsSubmitting(false); // Kết thúc quá trình gửi dữ liệu
    }
  };




  const handleExportCSV = async () => {
    const token = sessionStorage.getItem('_umsid');

    if (!token) {
      alert('Token không tồn tại. Vui lòng đăng nhập lại.');
      return;
    }

    setIsExporting(true);
    setExportError(null);

    try {
      const response = await fetch(`${apiUrlProduction}/admin/export-csv`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ searchResults }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'searchResults.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        alert('Xuất file CSV thành công!');
      } else if (response.status === 403) {
        alert('Bạn không có quyền thực hiện chức năng này. Vui lòng kiểm tra lại quyền của bạn.');
        setExportError('403');
      } else if (response.status === 401) {
        alert('Phiên làm việc của bạn đã hết hạn. Vui lòng đăng nhập lại để tiếp tục.');
        setExportError('401');
      } else {
        alert('Có lỗi xảy ra khi xuất dữ liệu. Vui lòng thử lại sau.');
        console.error('Lỗi:', await response.text());
        setExportError('generic');
      }
    } catch (error) {
      console.error('Error exporting data', error.message);
      alert('Có lỗi xảy ra khi kết nối đến server. Vui lòng thử lại sau.');
      setExportError('connection');
    } finally {
      setIsExporting(false);
    }
  };





  useEffect(() => {
    const fetchTotalMemberCount = async () => {
      try {
        const response = await fetch(`${apiUrlProduction}/admin/countTotalMember`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTotalCount(data.totalCount);

      } catch (error) {
        setError(error.message);
      }
    };

    fetchTotalMemberCount();
  }, []);

  useEffect(() => {
    const fetchTerminatedMemberCount = async () => {
      try {
        const response = await fetch(`${apiUrlProduction}/admin/countTerminated`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTerminatedCount(data.terminatedCount)

      } catch (error) {
        setError(error.message);
      }
    };
    fetchTerminatedMemberCount()


  }, [])

  useEffect(() => {
    const fetchSouthOwerCount = async () => {
      try {
        const response = await fetch(`${apiUrlProduction}/admin/countSouthOwner`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSouthOwnerCount(data.cshNam)

      } catch (error) {
        setError(error.message);
      }
    };
    fetchSouthOwerCount()


  }, [])

  useEffect(() => {
    const fetchNorthOwerCount = async () => {
      try {
        const response = await fetch(`${apiUrlProduction}/admin/countNorthOwner`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setNorthOwnerCount(data.cshBac)

      } catch (error) {
        setError(error.message);
      }
    };
    fetchNorthOwerCount()


  }, [])


  const handleGetTotalMembers = async () => {
    setLoading(true); 
  
    try {
      const response = await fetch(`${apiUrlProduction}/admin/click-to-getTotalMembers`);
  
      if (!response.ok) {
        throw new Error('Lỗi khi gọi API');
      }
  
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Lỗi khi tải dữ liệu:', error);
    } finally {
      setLoading(false); 
    }
  };
  


  const handleGetTerminatedMembers = async () => {
    setLoading(true); 
  
    try {
      const response = await fetch(`${apiUrlProduction}/admin/click-to-getTerminatedMembers`);
  
      if (!response.ok) {
        throw new Error('Lỗi khi gọi API');
      }
  
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Lỗi khi tải dữ liệu:', error);
    } finally {
      setLoading(false); 
    }
  };

  const handleGetSouthOwner = async () => {
    setLoading(true); 
  
    try {
      const response = await fetch(`${apiUrlProduction}/admin/click-to-getSouthOwner`);
  
      if (!response.ok) {
        throw new Error('Lỗi khi gọi API');
      }
  
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Lỗi khi tải dữ liệu:', error);
    } finally {
      setLoading(false); 
    }
  };

  const handleGetNorthOwner = async () => {
    setLoading(true); 
  
    try {
      const response = await fetch(`${apiUrlProduction}/admin/click-to-getNorthOwner`);
  
      if (!response.ok) {
        throw new Error('Lỗi khi gọi API');
      }
  
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Lỗi khi tải dữ liệu:', error);
    } finally {
      setLoading(false); 
    }
  };




  const formatDate = (date) => {
    if (!date) return 'N/A';
    const dateTime = new Date(date);
  
    // Lấy ngày, tháng, năm và đảm bảo ngày/tháng luôn có 2 chữ số
    const day = String(dateTime.getDate()).padStart(2, '0');
    const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // getMonth() trả về tháng từ 0-11
    const year = dateTime.getFullYear();
  
    const formattedDate = `${day}/${month}/${year}`;
  
    const hasTime = date.includes('T');
  
    if (hasTime) {
      const formattedTime = dateTime.toLocaleTimeString('vi-VN', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
      return `${formattedDate} ${formattedTime}`;
    }
  
    return formattedDate !== 'Invalid Date' ? formattedDate : 'N/A';
  };
  




  const promptModifyContract = (memberId) => {

    const memberDataToModify = searchResults.find(member => {
      return member._id === memberId;
    });

    setMemberDataIdToModify(memberDataToModify._id);

    setModifyMemberData({
      tinh_trang: memberDataToModify.tinh_trang,
      code: memberDataToModify.code,
      ten_tac_gia: memberDataToModify.ten_tac_gia,
      tg_nhac_loi: memberDataToModify.tg_nhac_loi,
      but_danh: memberDataToModify.but_danh,
      ngay_sinh: memberDataToModify.ngay_sinh,
      ngay_mat: memberDataToModify.ngay_mat,
      nguoi_dai_dien: memberDataToModify.nguoi_dai_dien,
      so_hop_dong: memberDataToModify.so_hop_dong,
      ngay_ky_hop_dong: memberDataToModify.ngay_ky_hop_dong,
      ngay_tai_ky: memberDataToModify.ngay_tai_ky,
      ngay_het_han_hop_dong: memberDataToModify.ngay_het_han_hop_dong,
      loai_hop_dong: memberDataToModify.loai_hop_dong,
      thoi_gian_nhac_gia_han: memberDataToModify.thoi_gian_nhac_gia_han,
      csh: memberDataToModify.csh,
      dien_thoai: memberDataToModify.dien_thoai,
      email_address: memberDataToModify.email_address,
      cmnd_cccd: memberDataToModify.cmnd_cccd,
      thong_tin_ngan_hang: memberDataToModify.thong_tin_ngan_hang,
      dia_chi_lien_lac: memberDataToModify.dia_chi_lien_lac,
      dia_chi_thuong_tru: memberDataToModify.dia_chi_thuong_tru,
      ngay_thanh_ly: memberDataToModify.ngay_thanh_ly,
      note: memberDataToModify.note,

    });
    setShowConfirmModify(true);
  };


  const handleConfirmModify = async (event) => {
    event.preventDefault();
    setLoading(true);
    const token = sessionStorage.getItem('_umsid');

    if (!token) {
      alert('Token không tồn tại. Vui lòng đăng nhập lại.');
      setIsSubmitting(false);
      setLoading(false);
      return;
    }

    let updated_by = '';
    try {
      const decodedToken = jwtDecode(token);
      updated_by = decodedToken.username;
    } catch (error) {
      console.error('Lỗi khi giải mã token:', error.message);
      alert('Có lỗi xảy ra khi giải mã token. Vui lòng thử lại sau.');
      setLoading(false);
      return;
    }

    const modifiedData = {
      ...modifyMemberData,
      updated_by,
      updated_at: new Date()

    }
    try {
      // Gửi yêu cầu PUT đến backend
      const response = await fetch(`${apiUrlProduction}/admin/modify-data/${memberDataIdToModify}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,

        },
        body: JSON.stringify(modifiedData),
      });

      if (response.ok) {
        // Cập nhật state với thông tin mới
        setSearchResults(prevMemberInfo => {
          const updatedMemberInfo = prevMemberInfo.map(member => {
            if (member._id === memberDataIdToModify) {
              return {
                ...member,
                tinh_trang: modifyMemberData.tinh_trang,
                code: modifyMemberData.code,
                ten_tac_gia: modifyMemberData.ten_tac_gia,
                tg_nhac_loi: modifyMemberData.tg_nhac_loi,
                but_danh: modifyMemberData.but_danh,
                ngay_sinh: modifyMemberData.ngay_sinh,
                ngay_mat: modifyMemberData.ngay_mat,
                nguoi_dai_dien: modifyMemberData.nguoi_dai_dien,
                so_hop_dong: modifyMemberData.so_hop_dong,
                ngay_ky_hop_dong: modifyMemberData.ngay_ky_hop_dong,
                ngay_tai_ky: modifyMemberData.ngay_tai_ky,
                ngay_het_han_hop_dong: modifyMemberData.ngay_het_han_hop_dong,
                loai_hop_dong: modifyMemberData.loai_hop_dong,
                thoi_gian_nhac_gia_han: modifyMemberData.thoi_gian_nhac_gia_han,
                csh: modifyMemberData.csh,
                dien_thoai: modifyMemberData.dien_thoai,
                email_address: modifyMemberData.email_address,
                cmnd_cccd: modifyMemberData.cmnd_cccd,
                thong_tin_ngan_hang: modifyMemberData.thong_tin_ngan_hang,
                dia_chi_lien_lac: modifyMemberData.dia_chi_lien_lac,
                dia_chi_thuong_tru: modifyMemberData.dia_chi_thuong_tru,
                ngay_thanh_ly: modifyMemberData.ngay_thanh_ly,
                note: modifyMemberData.note
              };
            }
            return member;
          });
          return updatedMemberInfo;
        });

        // Hiển thị thông báo thành công
        alert('Cập nhật thành công');
        setShowConfirmModify(false);
      } else {
        // Nếu có lỗi xảy ra, hiển thị thông báo lỗi
        const data = await response.json();
        alert(data.message);
      }
    } catch (error) {
      console.error('Lỗi:', error);
      alert('Có lỗi xảy ra');
    }
    finally {
      setShowConfirmModify(false);
      setIsSubmitting(false);
      setMemberDataIdToModify(null);
      setLoading(false);
    }
  };


  const promptViewContractHistory = async (contractId) => {
    setContractIdToViewHistory(contractId);
    setLoading(true);

    // console.log("testContractID", contractId);

    try {
      const response = await fetch(`${apiUrlProduction}/admin/view-history/${contractId}`);
      if (!response.ok) {
        const errorMessage = await response.json();
        alert(errorMessage.message);
        setLoading(false);

        return;
      }

      const historyData = await response.json();

      console.log("test-history-data", historyData)
      setHistoryData(historyData);
      setLoading(false);
      setShowHistoryModal(true);
    } catch (error) {
      console.error('Lỗi:', error);
      alert('Có lỗi xảy ra khi lấy lịch sử');


    } finally {
      setLoading(false);

    }
  };



  return (
    <div className="main-content-container">
      {loading &&
        <div class="overlay">
          <div class="lds-roller-main-content"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <div className='main-content-header'>
        <div className="info-boxes">

          <div className="info-box" onClick={handleGetTotalMembers}>
            <div className="info-title">Tổng số thành viên</div>
            <div className="info-number">{totalCount !== null ? totalCount : 'Loading...'}</div>
          </div>


          <div className="info-box" onClick={handleGetTerminatedMembers}>
            <div className="info-title">Thanh lý</div>
            <div className="info-number">{terminatedCount !== null ? terminatedCount : 'Loading...'}</div>
          </div>


          <div className="info-box" onClick={handleGetSouthOwner}>
            <div className="info-title">CSH Nam</div>
            <div className="info-number">{southOwnerCount !== null ? southOwnerCount : 'Loading...'}</div>
          </div>
          <div className="info-box" onClick={handleGetNorthOwner}>
            <div className="info-title">CSH Bắc</div>
            <div className="info-number">{northOwnerCount !== null ? northOwnerCount : 'Loading...'}</div>
          </div>
        </div>

        <div className='function-bars'>
          <div className="search-bars">
            <div className="search-bar">
              <label htmlFor="contract-number">Code:</label>
              <input
                type="text"
                placeholder="Nhập mã code"
                value={searchByCode}
                onChange={(e) => setSearchByCode(e.target.value)}
              />
            </div>

            <div className="search-bar">
              <label htmlFor="author-name">Tên tác giả:</label>
              <input
                type="text"
                placeholder="Nhập tên tác giả"
                value={searchByAuthorName}
                onChange={(e) => setSearchByAuthorName(e.target.value)}
              />
            </div>

            <div className="search-bar">
              <label htmlFor="pen-name">Bút danh:</label>
              <input
                type="text"
                value={searchByPenName}
                placeholder="Nhập bút danh"
                onChange={(e) => setSearchByPenName(e.target.value)}
              />
            </div>

            <div className="search-bar">
              <label htmlFor="pen-name">Số hợp đồng:</label>
              <input
                type="text"
                value={searchByContract}
                placeholder="Nhập số hợp đồng"
                onChange={(e) => setSearchByContract(e.target.value)}
              />
            </div>

            <div className="search-button-container">
              <button className="search-button" onClick={handleSearch}>Tìm kiếm</button>
            </div>
          </div>
        </div>

        <div className='filter-container'>
          <div className='filter-bars'>
            <div className="filter-bar">
              <label htmlFor="status">Trạng thái:</label>
              <select
                id="status"
                value={filterByStatus}
                onChange={(e) => setFilterByStatus(e.target.value)}
              >
                <option value="">Tất cả</option>
                <option value="Ký mới">Ký mới</option>
                <option value="Tái ký lần 1">Tái ký lần 1</option>
                <option value="Tái ký lần 2">Tái ký lần 2</option>
                <option value="Tái ký lần 3">Tái ký lần 3</option>
                <option value="Tái ký lần 4">Tái ký lần 4</option>
                <option value="Tái ký lần 5">Tái ký lần 5</option>
                <option value="Tái ký lần 6">Tái ký lần 6</option>
                <option value="Tái ký lần 7">Tái ký lần 7</option>
                <option value="Tái ký lần 8">Tái ký lần 8</option>
                <option value="Tái ký lần 9">Tái ký lần 9</option>
                <option value="Tái ký lần 10">Tái ký lần 10</option>
                <option value="Thanh lý">Thanh lý</option>
              </select>
            </div>

            <div className="filter-bar">
              <label>Ngày ký từ:</label>
              <input
                type="date"
                value={filterByDateFrom}
                onChange={(e) => setFilterByDateFrom(e.target.value)}
              />
            </div>
            <div className="filter-bar">
              <label>Ngày ký đến:</label>
              <input
                type="date"
                value={filterByDateTo}
                onChange={(e) => setFilterByDateTo(e.target.value)}
              />
            </div>
            <div className="filter-button-container">
              <button className="filter-button" onClick={handleFilter}>
                Lọc
              </button>
            </div>
          </div>
          <div className='add-new-button-container'>
            <button className="add-new-button" onClick={handleOpenAddNewPopup}>Thêm mới</button>
          </div>


        </div>
      </div>

      

      <div className='search-results-container'>
        <div className='search-results-container-header'>
          <h5>Kết quả tìm kiếm: {searchResults.length}</h5>
          <div className='export-csv-button-container'>
            <button
              className='export-csv-button'
              onClick={handleExportCSV}
              disabled={isExporting || exportError}
            >
              {isExporting ? 'Đang xuất...' : 'Xuất file CSV'}
            </button>
          </div>

        </div>
        <table className="results-table">
          <thead>
            <tr>
              <th>STT</th>
              <th>Trạng thái</th>
              <th>Code</th>
              <th>Tên tác giả</th>
              <th>Bút danh</th>
              <th>Số hợp đồng</th>
              <th>Ngày ký</th>
              <th className='action-column'>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.length > 0 ? (
              searchResults.map((result, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{result.tinh_trang}</td>
                  <td>{result.code}</td>
                  <td>{result.ten_tac_gia}</td>
                  <td>{result.but_danh}</td>
                  <td>{result.so_hop_dong}</td>
                  <td>{formatDate(result.ngay_ky_hop_dong)}</td>
                  <td className='action-button'>
                    <button
                      className="detail-button"
                      onClick={(event) => handleGetDetail(event, result._id)}
                    >
                      Chi tiết
                    </button>
                    <button onClick={() => promptModifyContract(result._id)} className="edit-button">Điều chỉnh</button>
                    <button onClick={() => promptViewContractHistory(result._id)} className="history-button">Lịch sử</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">Không có kết quả tìm kiếm nào.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>




      {showDetailPopup && (
        <div className="view-detail-data-modal">
          <div className="view-detail-data-content">
            <h2 className='view-detail-data-title'>Thông Tin Chi Tiết</h2>
            <button className="close-button" onClick={handleCloseDetailPopup}>
              Đóng
            </button>

            <div className='view-detail-data-table'>
              <table>
                <thead>
                  <tr>
                    <th>Trạng thái</th>
                    <th>Code</th>
                    <th>Tên tác giả</th>
                    <th>Tác giả nhạc/lời</th>
                    <th>Bút danh</th>
                    <th>Ngày sinh</th>
                    <th>Ngày mất</th>
                    <th>Người đại diện</th>
                    <th>Số hợp đồng</th>
                    <th>Ngày ký HĐ</th>
                    <th>Ngày tái ký</th>
                    <th>Ngày hết hạn HĐ</th>
                    <th>Loại hợp đồng</th>
                    <th>TG nhắc GH</th>
                    <th>CSH</th>
                    <th>Điện thoại</th>
                    <th>Email</th>
                    <th>CMND/CCCD</th>
                    <th>Thông tin ngân hàng</th>
                    <th>Địa chỉ liên lạc</th>
                    <th>Địa chỉ thường trú</th>
                    <th>Ngày thanh lý</th>
                    <th>Ghi chú</th>
                    <th>Người tạo</th>
                    <th>Thời gian tạo</th>
                    <th>Người cập nhật</th>
                    <th>Thời gian cập nhật</th>
                  </tr>
                </thead>
                <tbody>
                  {detailData ? (
                    <tr>
                      <td>{detailData.tinh_trang || 'N/A'}</td>
                      <td>{detailData.code || 'N/A'}</td>
                      <td>{detailData.ten_tac_gia || 'N/A'}</td>
                      <td>{detailData.tg_nhac_loi || 'N/A'}</td>
                      <td>{detailData.but_danh || 'N/A'}</td>
                      <td>{formatDate(detailData.ngay_sinh) || 'N/A'}</td>
                      <td>{formatDate(detailData.ngay_mat) || 'N/A'}</td>
                      <td>{detailData.nguoi_dai_dien || 'N/A'}</td>
                      <td>{detailData.so_hop_dong || 'N/A'}</td>
                      <td>{formatDate(detailData.ngay_ky_hop_dong) || 'N/A'}</td>
                      <td>{formatDate(detailData.ngay_tai_ky) || 'N/A'}</td>
                      <td>{formatDate(detailData.ngay_het_han_hop_dong) || 'N/A'}</td>
                      <td>{detailData.loai_hop_dong || 'N/A'}</td>
                      <td>{detailData.thoi_gian_nhac_gia_han || 'N/A'}</td>
                      <td>{detailData.csh || 'N/A'}</td>
                      <td>{detailData.dien_thoai || 'N/A'}</td>
                      <td>{detailData.email_address || 'N/A'}</td>
                      <td>{detailData.cmnd_cccd || 'N/A'}</td>
                      <td>{detailData.thong_tin_ngan_hang || 'N/A'}</td>
                      <td>{detailData.dia_chi_lien_lac || 'N/A'}</td>
                      <td>{detailData.dia_chi_thuong_tru || 'N/A'}</td>
                      <td>{formatDate(detailData.ngay_thanh_ly) || 'N/A'}</td>
                      <td>{detailData.note || 'N/A'}</td>
                      <td>{detailData.created_by || 'N/A'}</td>
                      <td>{formatDate(detailData.created_at) || 'N/A'}</td>
                      <td>{detailData.updated_by || 'N/A'}</td>
                      <td>{formatDate(detailData.updated_at) || 'N/A'}</td>
                      <span style={{ color: 'white' }}>''</span>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="14">Không có dữ liệu chi tiết</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {showAddNewPopup && (
        <div className="add-new-data-modal">
          <div className="add-new-data-content">
            <button className="close-button" onClick={handleCloseAddNewPopup}>
              Đóng
            </button>
            <h2 className="add-new-data-title">Nhập Thông Tin Chi Tiết</h2>

            <form onSubmit={handleAddNew}>
              <div className="form-group">
                <label>Trạng thái:<span className="required">*</span></label>
                <select id="newCSH" name="tinh_trang" onChange={handleInputChange} value={newMemberData.tinh_trang} required>
                  <option value="Ký mới">Ký mới</option>
                  <option value="Tái ký lần 1">Tái ký lần 1</option>
                  <option value="Tái ký lần 2">Tái ký lần 2</option>
                  <option value="Tái ký lần 3">Tái ký lần 3</option>
                  <option value="Tái ký lần 4">Tái ký lần 4</option>
                  <option value="Tái ký lần 5">Tái ký lần 5</option>
                  <option value="Tái ký lần 6">Tái ký lần 6</option>
                  <option value="Tái ký lần 7">Tái ký lần 7</option>
                  <option value="Tái ký lần 8">Tái ký lần 8</option>
                  <option value="Tái ký lần 9">Tái ký lần 9</option>
                  <option value="Tái ký lần 10">Tái ký lần 10</option>
                  <option value="Thanh lý">Thanh lý</option>
                </select>
              </div>

              <div className="form-group">
                <label>Code:</label>
                <input type="text" name="code" onChange={handleInputChange} value={newMemberData.code} />
              </div>
              <div className="form-group">
                <label>Tên tác giả:<span className="required">*</span></label>
                <input type="text" name="ten_tac_gia" onChange={handleInputChange} value={newMemberData.ten_tac_gia} required />
              </div>
              <div className="form-group">
                <label>TG nhạc/lời:</label>
                <input type="text" name="tg_nhac_loi" onChange={handleInputChange} value={newMemberData.tg_nhac_loi} />
              </div>
              <div className="form-group">
                <label>Bút danh:<span className="required">*</span></label>
                <input type="text" name="but_danh" onChange={handleInputChange} value={newMemberData.but_danh} required />
              </div>
              <div className="form-group">
                <label>Ngày sinh:</label>
                <input type="date" name="ngay_sinh" onChange={handleInputChange} value={newMemberData.ngay_sinh} />
              </div>
              <div className="form-group">
                <label>Ngày mất:</label>
                <input type="date" name="ngay_mat" onChange={handleInputChange} value={newMemberData.ngay_mat} />
              </div>
              <div className="form-group">
                <label>Người đại diện:</label>
                <input type="text" name="nguoi_dai_dien" onChange={handleInputChange} value={newMemberData.nguoi_dai_dien} />
              </div>
              <div className="form-group">
                <label>Số HĐ:</label>
                <input type="text" name="so_hop_dong" onChange={handleInputChange} value={newMemberData.so_hop_dong} />
              </div>
              <div className="form-group">
                <label>Ngày ký HĐ:<span className="required">*</span></label>
                <input type="date" name="ngay_ky_hop_dong" onChange={handleInputChange} value={newMemberData.ngay_ky_hop_dong} required />
              </div>
              <div className="form-group">
                <label>Ngày tái ký:</label>
                <input type="date" name="ngay_tai_ky" onChange={handleInputChange} value={newMemberData.ngay_tai_ky} />
              </div>
              <div className="form-group">
                <label>Ngày hết hạn HĐ:<span className="required">*</span></label>
                <input type="date" name="ngay_het_han_hop_dong" onChange={handleInputChange} value={newMemberData.ngay_het_han_hop_dong} required />
              </div>
              <div className="form-group">
                <label>Loại HĐ:<span className="required">*</span></label>
                <select name="loai_hop_dong" onChange={handleInputChange} value={newMemberData.loai_hop_dong} required>
                  <option value="Tự động GH">Tự động GH</option>
                  <option value="Không tự GH">Không tự GH</option>
                </select>
              </div>
              <div className="form-group">
                <label>Thời gian nhắc GH:</label>
                <input type="number" name="thoi_gian_nhac_gia_han" onChange={handleInputChange} value={newMemberData.thoi_gian_nhac_gia_han} />
              </div>
              <div className="form-group">
                <label>CSH:<span className="required">*</span></label>
                <select id="newCSH" name="csh" onChange={handleInputChange} value={newMemberData.csh} required>
                  <option value="Nam">Nam</option>
                  <option value="Bắc">Bắc</option>
                </select>
              </div>
              <div className="form-group">
                <label>Điện thoại:</label>
                <input type="tel" name="dien_thoai" onChange={handleInputChange} value={newMemberData.dien_thoai} />
              </div>
              <div className="form-group">
                <label>Email:</label>
                <input type="email" name="email_address" onChange={handleInputChange} value={newMemberData.email_address} />
              </div>
              <div className="form-group">
                <label>CMND/CCCD:</label>
                <input type="text" name="cmnd_cccd" onChange={handleInputChange} value={newMemberData.cmnd_cccd} />
              </div>
              <div className="form-group">
                <label>Thông tin ngân hàng:</label>
                <input type="text" name="thong_tin_ngan_hang" onChange={handleInputChange} value={newMemberData.thong_tin_ngan_hang} />
              </div>
              <div className="form-group">
                <label>Địa chỉ liên lạc:</label>
                <input type="text" name="dia_chi_lien_lac" onChange={handleInputChange} value={newMemberData.dia_chi_lien_lac} />
              </div>
              <div className="form-group">
                <label>Địa chỉ thường trú:</label>
                <input type="text" name="dia_chi_thuong_tru" onChange={handleInputChange} value={newMemberData.dia_chi_thuong_tru} />
              </div>
              <div className="form-group">
                <label>Ngày thanh lý:</label>
                <input type="date" name="ngay_thanh_ly" onChange={handleInputChange} value={newMemberData.ngay_thanh_ly} />
              </div>
              <div className="form-group">
                <label>Ghi chú:</label>
                <textarea name="note" onChange={handleInputChange} value={newMemberData.note}></textarea>
              </div>

              <button
                type="submit"
                className="submit-button"
                disabled={isSubmitting || submitError}
              >
                {isSubmitting ? 'Đang thêm...' : 'Thêm Mới'}
              </button>
            </form>
          </div>
        </div>
      )}


      {/* MODIFY */}
      {showConfirmModify && (
        <div className="mofify-data-modal">
          <div className="modify-data-content">
            <button className="close-button" onClick={handleCloseModifyPopup}>
              Đóng
            </button>
            <h2 className="modify-data-title">Cập Nhật Thông Tin</h2>

            <form onSubmit={handleConfirmModify}>
              <div className="form-group">
                <label>Trạng thái:<span className="required">*</span></label>
                <select id="newCSH" name="tinh_trang" onChange={handleModifyInputChange} value={modifyMemberData.tinh_trang} required>
                  <option value="Ký mới">Ký mới</option>
                  <option value="Tái ký lần 1">Tái ký lần 1</option>
                  <option value="Tái ký lần 2">Tái ký lần 2</option>
                  <option value="Tái ký lần 3">Tái ký lần 3</option>
                  <option value="Tái ký lần 4">Tái ký lần 4</option>
                  <option value="Tái ký lần 5">Tái ký lần 5</option>
                  <option value="Tái ký lần 6">Tái ký lần 6</option>
                  <option value="Tái ký lần 7">Tái ký lần 7</option>
                  <option value="Tái ký lần 8">Tái ký lần 8</option>
                  <option value="Tái ký lần 9">Tái ký lần 9</option>
                  <option value="Tái ký lần 10">Tái ký lần 10</option>
                  <option value="Thanh lý">Thanh lý</option>
                </select>
              </div>

              <div className="form-group">
                <label>Code:</label>
                <input type="text" name="code" onChange={handleModifyInputChange} value={modifyMemberData.code} />
              </div>
              <div className="form-group">
                <label>Tên tác giả:<span className="required">*</span></label>
                <input type="text" name="ten_tac_gia" onChange={handleModifyInputChange} value={modifyMemberData.ten_tac_gia} required />
              </div>
              <div className="form-group">
                <label>TG nhạc/lời:</label>
                <input type="text" name="tg_nhac_loi" onChange={handleModifyInputChange} value={modifyMemberData.tg_nhac_loi} />
              </div>
              <div className="form-group">
                <label>Bút danh:<span className="required">*</span></label>
                <input type="text" name="but_danh" onChange={handleModifyInputChange} value={modifyMemberData.but_danh} required />
              </div>
              <div className="form-group">
                <label>Ngày sinh:</label>
                <input type="date" name="ngay_sinh" onChange={handleModifyInputChange} value={modifyMemberData.ngay_sinh} />
              </div>
              <div className="form-group">
                <label>Ngày mất:</label>
                <input type="date" name="ngay_mat" onChange={handleModifyInputChange} value={modifyMemberData.ngay_mat} />
              </div>
              <div className="form-group">
                <label>Người đại diện:</label>
                <input type="text" name="nguoi_dai_dien" onChange={handleModifyInputChange} value={modifyMemberData.nguoi_dai_dien} />
              </div>
              <div className="form-group">
                <label>Số HĐ:</label>
                <input type="text" name="so_hop_dong" onChange={handleModifyInputChange} value={modifyMemberData.so_hop_dong} />
              </div>
              <div className="form-group">
                <label>Ngày ký HĐ:<span className="required">*</span></label>
                <input type="date" name="ngay_ky_hop_dong" onChange={handleModifyInputChange} value={modifyMemberData.ngay_ky_hop_dong} required />
              </div>
              <div className="form-group">
                <label>Ngày tái ký:</label>
                <input type="date" name="ngay_tai_ky" onChange={handleModifyInputChange} value={modifyMemberData.ngay_tai_ky} />
              </div>
              <div className="form-group">
                <label>Ngày hết hạn HĐ:<span className="required">*</span></label>
                <input type="date" name="ngay_het_han_hop_dong" onChange={handleModifyInputChange} value={modifyMemberData.ngay_het_han_hop_dong} required />
              </div>
              <div className="form-group">
                <label>Loại HĐ:<span className="required">*</span></label>
                <select name="loai_hop_dong" onChange={handleModifyInputChange} value={modifyMemberData.loai_hop_dong} required>
                  <option value="Tự động GH">Tự động GH</option>
                  <option value="Không tự GH">Không tự GH</option>
                </select>
              </div>
              <div className="form-group">
                <label>Thời gian nhắc GH:</label>
                <input type="number" name="thoi_gian_nhac_gia_han" onChange={handleModifyInputChange} value={modifyMemberData.thoi_gian_nhac_gia_han} />
              </div>
              <div className="form-group">
                <label>CSH:<span className="required">*</span></label>
                <select id="newCSH" name="csh" onChange={handleModifyInputChange} value={modifyMemberData.csh} required>
                  <option value="Nam">Nam</option>
                  <option value="Bắc">Bắc</option>
                </select>
              </div>
              <div className="form-group">
                <label>Điện thoại:</label>
                <input type="tel" name="dien_thoai" onChange={handleModifyInputChange} value={modifyMemberData.dien_thoai} />
              </div>
              <div className="form-group">
                <label>Email:</label>
                <input type="email" name="email_address" onChange={handleModifyInputChange} value={modifyMemberData.email_address} />
              </div>
              <div className="form-group">
                <label>CMND/CCCD:</label>
                <input type="text" name="cmnd_cccd" onChange={handleModifyInputChange} value={modifyMemberData.cmnd_cccd} />
              </div>
              <div className="form-group">
                <label>Thông tin ngân hàng:</label>
                <input type="text" name="thong_tin_ngan_hang" onChange={handleModifyInputChange} value={modifyMemberData.thong_tin_ngan_hang} />
              </div>
              <div className="form-group">
                <label>Địa chỉ liên lạc:</label>
                <input type="text" name="dia_chi_lien_lac" onChange={handleModifyInputChange} value={modifyMemberData.dia_chi_lien_lac} />
              </div>
              <div className="form-group">
                <label>Địa chỉ thường trú:</label>
                <input type="text" name="dia_chi_thuong_tru" onChange={handleModifyInputChange} value={modifyMemberData.dia_chi_thuong_tru} />
              </div>
              <div className="form-group">
                <label>Ngày thanh lý:</label>
                <input type="date" name="ngay_thanh_ly" onChange={handleModifyInputChange} value={modifyMemberData.ngay_thanh_ly} />
              </div>
              <div className="form-group">
                <label>Ghi chú:</label>
                <textarea name="note" onChange={handleModifyInputChange} value={modifyMemberData.note}></textarea>
              </div>

              <button
                type="submit"
                className="submit-button"
                disabled={isSubmitting || submitError}
              >
                {isSubmitting ? 'Đang lưu...' : 'Lưu Thay Đổi'}
              </button>
            </form>
          </div>
        </div>
      )}


      {showHistoryModal && (
        <div className="view-detail-data-modal">
          <div className="view-detail-data-content">
            <h2 className='view-detail-data-title'>Thông Tin Lịch Sử</h2>
            <button className="close-button" onClick={handleCloseHistoryPopup}>
              Đóng
            </button>
            <div className='view-detail-data-table'>
              <table>
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Trạng thái</th>
                    <th>Code</th>
                    <th>Tên tác giả</th>
                    <th>Tác giả nhạc/lời</th>
                    <th>Bút danh</th>
                    <th>Ngày sinh</th>
                    <th>Ngày mất</th>
                    <th>Người đại diện</th>
                    <th>Số hợp đồng</th>
                    <th>Ngày ký HĐ</th>
                    <th>Ngày tái ký</th>
                    <th>Ngày hết hạn HĐ</th>
                    <th>Loại hợp đồng</th>
                    <th>TG nhắc GH</th>
                    <th>CSH</th>
                    <th>Điện thoại</th>
                    <th>Email</th>
                    <th>CMND/CCCD</th>
                    <th>Thông tin ngân hàng</th>
                    <th>Địa chỉ liên lạc</th>
                    <th>Địa chỉ thường trú</th>
                    <th>Ngày thanh lý</th>
                    <th>Ghi chú</th>
                    <th>Người tạo</th>
                    <th>Thời gian tạo</th>
                    <th>Người cập nhật</th>
                    <th>Thời gian cập nhật</th>

                    <span style={{ color: 'white' }}>''</span>

                  </tr>
                </thead>
                <tbody>
                  {historyData && historyData.length > 0 ? (
                    historyData.map((history, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{history.tinh_trang || 'N/A'}</td>
                        <td>{history.code || 'N/A'}</td>
                        <td>{history.ten_tac_gia || 'N/A'}</td>
                        <td>{history.tg_nhac_loi || 'N/A'}</td>
                        <td>{history.but_danh || 'N/A'}</td>
                        <td>{formatDate(history.ngay_sinh) || 'N/A'}</td>
                        <td>{formatDate(history.ngay_mat) || 'N/A'}</td>
                        <td>{history.nguoi_dai_dien || 'N/A'}</td>
                        <td>{history.so_hop_dong || 'N/A'}</td>
                        <td>{formatDate(history.ngay_ky_hop_dong) || 'N/A'}</td>
                        <td>{formatDate(history.ngay_tai_ky) || 'N/A'}</td>
                        <td>{formatDate(history.ngay_het_han_hop_dong) || 'N/A'}</td>
                        <td>{history.loai_hop_dong || 'N/A'}</td>
                        <td>{history.thoi_gian_nhac_gia_han || 'N/A'}</td>
                        <td>{history.csh || 'N/A'}</td>
                        <td>{history.dien_thoai || 'N/A'}</td>
                        <td>{history.email_address || 'N/A'}</td>
                        <td>{history.cmnd_cccd || 'N/A'}</td>
                        <td>{history.thong_tin_ngan_hang || 'N/A'}</td>
                        <td>{history.dia_chi_lien_lac || 'N/A'}</td>
                        <td>{history.dia_chi_thuong_tru || 'N/A'}</td>
                        <td>{formatDate(history.ngay_thanh_ly) || 'N/A'}</td>
                        <td>{history.note || 'N/A'}</td>
                        <td>{history.created_by || 'N/A'}</td>
                        <td>{formatDate(history.created_at) || 'N/A'}</td>
                        <td>{history.updated_by || 'N/A'}</td>
                        <td>{formatDate(history.updated_at) || 'N/A'}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="25">Không có dữ liệu chi tiết</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}





    </div>
  );
};

export default MainContentComponent;
