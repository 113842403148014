import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import '../Dashboard/dashboard.scss'
import logo from '../../images/vcpmc.gif'






const Dashboard = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState('');

    const apiUrlLocal = process.env.REACT_APP_API_URL_LOCAL;
    const apiUrlProduction = process.env.REACT_APP_API_URL_PRODUCTION;



    const fetchTestMessage = async () => {
        try {
            const response = await fetch(`${apiUrlProduction}/admin/test`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                setMessage(data.message);
            } else {
                console.error('Failed to fetch test message');
                setMessage("jwt expired");
            }
        } catch (error) {
            console.error('Error fetching test message:', error);
        }
    };


    const handleLogout = async () => {
        try {
            const response = await fetch(`${apiUrlProduction}/admin/logout`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include' // include credentials to send cookies
            });

            if (response.status === 204) {
                // Xóa cookie
                sessionStorage.removeItem('_umsid');

                navigate('/');
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleEditorAccess = async () => {
        try {
            const token = sessionStorage.getItem('_umsid');
            if (!token) {
                throw new Error('Không tìm thấy token trong sessionStorage');
            }

            const response = await fetch(`${apiUrlProduction}/admin/editor-endpoint`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
            
            if (response.ok) {
                const data = await response.json();
                setMessage(data.message)
                console.log('Message:', data.message);
                // Xử lý dữ liệu nhận được từ server tại đây
            } else if (response.status === 401) {
                alert('Phiên làm việc của bạn đã hết hạn. Vui lòng đăng nhập lại để tiếp tục.');
                navigate('/');
            } else if (response.status === 403) {
                alert('Bạn không có quyền truy cập.');
            } else {
                throw new Error('Lỗi không xác định từ máy chủ');
            }
        } catch (error) {
            console.error('Error:', error.message);
            alert('Đã xảy ra lỗi trong quá trình xử lý yêu cầu.');
        }
    };

    const handleViewerAccess = async () => {
        try {
            const token = sessionStorage.getItem('_umsid');
            if (!token) {
                throw new Error('Không tìm thấy token trong sessionStorage');
            }

            const response = await fetch(`${apiUrlProduction}/admin/viewer-endpoint`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            if (response.ok) {
                const data = await response.json();
                setMessage(data.message)
                console.log('Message:', data.message);
                // Xử lý dữ liệu nhận được từ server tại đây
            } else if (response.status === 401) {
                alert('Phiên làm việc của bạn đã hết hạn. Vui lòng đăng nhập lại để tiếp tục.');
                navigate('/');
            } else if (response.status === 403) {
                alert('Bạn không có quyền truy cập.');
            } else {
                throw new Error('Lỗi không xác định từ máy chủ');
            }
        } catch (error) {
            console.error('Error:', error.message);
            alert('Đã xảy ra lỗi trong quá trình xử lý yêu cầu.');
        }
    };


    return (
        <div className='dashboard-container'>
            <div className='dashboard-sidebar'>
                <div className='dashboard-menu'>
                    <img src={logo} alt="Logo" className="login-logo" />
                    <hr />
                    <ul>
                        <li>
                            <Link to="content" className="full-width-link">
                                <i className="fas fa-user"></i> TRANG QUẢN TRỊ
                            </Link>
                        </li>
                        <li>
                            <Link to="re-sign" className="full-width-link">
                                <i className="fas fa-file-signature"></i> ..............
                            </Link>
                        </li>
                    </ul>
                </div>

                <button onClick={handleLogout}>Đăng xuất</button>

            </div>

            <div className='dashboard-content'>
                <Outlet />
            </div>

        </div>
    );
};

export default Dashboard;






