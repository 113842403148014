import React, { useState, useEffect } from 'react';
import './Login.scss';
import { useNavigate } from "react-router-dom";
import logo from '../../images/vcpmc.gif'
import Cookies from 'js-cookie';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');


    const navigate = useNavigate();

    const apiUrlLocal = process.env.REACT_APP_API_URL_LOCAL;
    const apiUrlProduction = process.env.REACT_APP_API_URL_PRODUCTION;

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            const response = await fetch(`${apiUrlProduction}/admin/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            });
    
            const data = await response.json();
    
            if (response.ok) {
                sessionStorage.setItem('_umsid', data.accessToken);
                console.log('Đăng nhập thành công:', data.message);                
                navigate('/dashboard/content');
            } else {
                // console.log('Lỗi đăng nhập:', data.message); 
                alert(`Lỗi đăng nhập: ${data.message}`);

            }
        } catch (error) {
            // console.error(error.message);
            alert(`Đã xảy ra lỗi: ${error.message}`);

        } finally {
            setLoading(false);
        }
    };
    
    
    return (
        <div className="login-page">
            {loading &&
                <div class="overlay">
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            }
            <div className="login-container">
                <img src={logo} alt="Logo" className="login-logo" />

                <h2 className="login-title">Đăng nhập</h2>
                <form onSubmit={handleSubmit}>
                    <div className="login-form-group">
                        <label className='login-form-label'>Tài khoản</label>
                        <input
                            type="text"
                            className="login-form-input"
                            placeholder="Nhập tài khoản"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="login-form-group">
                        <label className='login-form-label'>Mật khẩu</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            className="login-form-input"
                            placeholder="Nhập mật khẩu"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="login-form-group login-checkbox">
                        <input
                            type="checkbox"
                            id="showPassword"
                            checked={showPassword}
                            onChange={toggleShowPassword}
                        />
                        <label htmlFor="showPassword">Hiển thị mật khẩu</label>
                    </div>
                    <button type="submit" className="login-form-btn">Đăng nhập</button>
                </form>
                {error && <p className="login-error">*{error}</p>}
            </div>
        </div>
    );
}

export default Login;
