import React from 'react';
import { Navigate } from 'react-router-dom';


function PrivateRoute({ children }) {
  const token = sessionStorage.getItem('_umsid');
  // console.log("token từ sessionStorage:", token);
  
  return token ? children : <Navigate to="/" />;
}

export default PrivateRoute;





